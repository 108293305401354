import React from "react";
import { Link, Button } from "gatsby-theme-material-ui";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import SEO from "../components/seo";

import nesteddolls from "../../src/images/nested-dolls.png";
import divider from "../../src/images/divider.png";
import abstractunpack from "../../src/images/abstract-unpackable.png";
import exampleunpack from "../../src/images/exampleunpack.png";

import "../sass/landing.scss";

function Landing() {
  return (
    <React.Fragment>
      <SEO title="Welcome" />
      <Container maxWidth="md" className="landing-page">
        <Grid item xs={12}>
          <Box className="text-center hero" mt={16} mb={10}>
            <Typography variant="h1">
              Let’s unpack self-learning, together.
            </Typography>
            <Typography variant="body1">
              A community for lifelong learners to share and discover their
              journeys towards learning something new.
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                className="landing-page-button"
                disableElevation
                to="/app"
              >
                Explore the Beta
              </Button>
            </Box>
            <Box>
              <img
                src={nesteddolls}
                className="hero-image"
                alt="illustration of nested dolls"
              />
            </Box>
            <Box>
              <img
                src={divider}
                className="divider"
                alt=""
                aria-hidden="true"
              />
            </Box>
          </Box>
        </Grid>
        <Grid container className="middle-block">
          <Grid item sm={5}>
            <img src={abstractunpack} alt="illustration of nested dolls" />
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item sm={6}>
            <Box className="text-block">
              <Typography variant="overline">For the self-taught</Typography>
              <Typography variant="h2">Share your journey</Typography>
              <Typography variant="body1">
                Break down your methods, tools and tips to create unpackables
                that help others find their self-learning journeys.
              </Typography>
            </Box>
            <Box className="text-block">
              <Typography variant="overline">For the self-learners</Typography>
              <Typography variant="h2">Unpack new skills</Typography>
              <Typography variant="body1">
                Discover inspiring unpackables to chart your own journey, shared
                by self-learners who have been there and done that.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className="text-center">
            <img src={divider} className="divider" alt="" aria-hidden="true" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="text-center bottom-block" mt={12} mb={12}>
            <Typography variant="h3">Welcome to unpackables!</Typography>
            <Box>
              <img src={exampleunpack} alt="example of an unpackable" />
            </Box>
            <Box className="overlapping-button">
              <Button
                variant="contained"
                className="landing-page-button"
                color="primary"
                disableElevation
                to="/app/unpack/how-i-started-a-photography-side-hustle"
              >
                See an Example
              </Button>
            </Box>
          </Box>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Landing;
